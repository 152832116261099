const deviceMediaQuery = "(max-device-width: 1024px)";
import { useEffect, useState } from "react";
function copyAndSort(items, columnKey, isSortedDescending) {
    const key = columnKey;
    return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, timeout);
    };
}
function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}
function trimString(value, maxLength) {
    let trimmedVal = value.trim();
    if (trimmedVal.length > maxLength) {
        return trimmedVal.substring(0, maxLength - 3) + "...";
    }
    return trimmedVal;
}
function getItemContent(item, displayConfig) {
    let key = displayConfig[0];
    let customRender = displayConfig[1];
    let formaters = displayConfig[2];
    let customRenderUseFormatData = displayConfig[3];
    let formatedData = item[key];
    if (formaters) {
        formaters.map(formatter => {
            formatedData = formatter(formatedData);
        });
    }
    if (customRender) {
        let itemDescription = customRenderUseFormatData ?
            customRender(Object.assign(Object.assign({}, item), { [key]: formatedData }))
            : customRender(item);
        return itemDescription;
    }
    else {
        return formatedData;
    }
}
// experimental
function searchIndexBased(items, searchQuery, isCaseSensitive = false, key) {
    let foundItems = [];
    if (searchQuery && searchQuery.length > 0) {
        const query = isCaseSensitive ? searchQuery : searchQuery.toLowerCase();
        let itemsWithWeight = items.map(item => {
            const weight = getWeight(item, query, isCaseSensitive, key);
            return [weight, item];
        });
        itemsWithWeight = itemsWithWeight.filter(ow => ow[0] > -1);
        itemsWithWeight.sort((a, b) => { return a[0] - b[0]; });
        foundItems = itemsWithWeight.map(opt => opt[1]);
    }
    else {
        foundItems = items;
    }
    return foundItems;
}
function getWeight(item, searchQuery, isCaseSensitive = false, key) {
    let weight = 0;
    let text;
    if (key == null) {
        text = isCaseSensitive ? String(item) : String(item).toLowerCase();
    }
    else {
        text = isCaseSensitive ? String(item[key]) : String(item[key]).toLowerCase();
    }
    let lastIndex = 0;
    for (let i = 0; i < searchQuery.length; i++) {
        let currentIndex = text.indexOf(searchQuery[i], lastIndex);
        if (currentIndex < 0) {
            return -1;
        }
        weight = weight + (currentIndex - lastIndex) * (searchQuery.length - i);
        lastIndex = currentIndex;
    }
    return weight;
}
const searchIndexRegexBased = (items, searchQuery, isCaseSensitive = false, key) => {
    let foundItems = [];
    const query = isCaseSensitive ? searchQuery : searchQuery.toLowerCase();
    if (searchQuery && searchQuery.length > 0) {
        let indexItemsTuple = items.map(item => {
            let index;
            if (key == null) {
                index = isCaseSensitive ? item.toString().indexOf(query) : item.toString().toLocaleLowerCase().indexOf(query);
            }
            else {
                index = isCaseSensitive ? item[key].toString().indexOf(query) : item[key].toString().toLocaleLowerCase().indexOf(query);
            }
            return [index, item];
        });
        let indexOptions = indexItemsTuple.filter(ow => ow[0] > -1).sort((a, b) => { return a[0] - b[0]; }).map(ot => ot[1]);
        let regexFound = searchRegex(items, query, isCaseSensitive, key);
        // In case that the current selected value should remain in case other should not be selected
        //let currentOption = items.find(io => io.value == dropdownData.value)
        //if (currentOption) {
        //    foundItems = [...new Set([currentOption, ...indexOptions, ...regexFound])]
        //}
        //else {
        //    foundItems = [...new Set([...indexOptions, ...regexFound])]
        //}
        foundItems = [...new Set([...indexOptions, ...regexFound])];
    }
    else {
        foundItems = items;
    }
    return foundItems;
};
function searchRegex(items, searchQuery, isCaseSensitive = false, key) {
    let foundItems = [];
    if (searchQuery && searchQuery.length > 0) {
        const modifier = isCaseSensitive ? "g" : "gi";
        let searchPattern = '.*' + searchQuery.split('').join(".*") + '.*';
        const searchRegex = new RegExp(searchPattern, modifier);
        if (key == null) {
            foundItems = items.filter(c => c.toString().match(searchRegex));
        }
        else {
            foundItems = items.filter(c => c[key].toString().match(searchRegex));
        }
    }
    else {
        foundItems = items;
    }
    return foundItems;
}
function isNullOrUndefined(value) {
    return value === null || value === undefined;
}
export { copyAndSort, debounce, trimString, deviceMediaQuery, getItemContent, useDebounce, isNullOrUndefined };
