import React, { useEffect, useState } from "react";
import { ControlledItemsCardView } from "./ControlledItemsCardView";
import { ControlledItemsTableView } from "./ControlledItemsTableView";
export const ControlledDynamicItemsViewer = (props) => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);
    return (React.createElement("div", { className: "dynamic-items-viewer" }, screenSize > props.cutoffScreenSize ? React.createElement(ControlledItemsTableView, Object.assign({}, props)) :
        React.createElement(ControlledItemsCardView, Object.assign({}, props))));
};
