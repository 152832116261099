var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Button, List, Segment } from "semantic-ui-react";
import { getItemContent } from "../../Utility/utility";
const ItemCard = (props) => {
    return (React.createElement(Segment, null,
        React.createElement(List, { relaxed: true },
            props.properties
                .filter((property) => { var _a; return ((_a = property.displayPlaces) === null || _a === void 0 ? void 0 : _a.indexOf("cardHeader")) > -1; })
                .map((property, index) => {
                return (React.createElement(List.Item, { as: "h3", key: index },
                    React.createElement(List.Header, null, property.label),
                    React.createElement(List.Content, null, getItemContent(props.item, [
                        property.key,
                        property.customRender,
                        property.formaters,
                        property.customRenderUseFormatData
                    ]))));
            }),
            props.properties
                .filter((property) => { var _a; return ((_a = property.displayPlaces) === null || _a === void 0 ? void 0 : _a.indexOf("card")) > -1; })
                .map((property, index) => {
                return (React.createElement(List.Item, { key: index },
                    React.createElement(List.Header, null, property.label),
                    React.createElement(List.Description, null, getItemContent(props.item, [
                        property.key,
                        property.customRender,
                        property.formaters,
                        property.customRenderUseFormatData
                    ]))));
            }),
            props.itemActions && (React.createElement(List.Item, null,
                React.createElement(List.Content, null, props.itemActions.map((actionProps, index) => {
                    const actionKey = actionProps.key + "_" + index + "_card";
                    const { displayOnCard, onClick, onHoverDescription } = actionProps, buttonProps = __rest(actionProps, ["displayOnCard", "onClick", "onHoverDescription"]);
                    if (displayOnCard) {
                        return (React.createElement(Button, Object.assign({ key: actionKey, onClick: (event, data) => {
                                actionProps.onClick(props.item);
                            } }, buttonProps)));
                    }
                })))))));
};
export { ItemCard };
