import React from "react";
import { Input, Pagination, Segment, Table } from "semantic-ui-react";
import { ItemsPerPageDropdown } from "../ItemsPerPageDropdown";
import { CustomTableHead } from "../CustomTableHead";
import { CustomTableRow } from "../CustomTableRow";
export const ControlledItemsTableView = (props) => {
    const { properties, items, itemActions } = props;
    const getNumberOfPages = () => {
        return props.pagination ? Math.ceil(props.numberOfItems / props.numberOfItemsShown) : 1;
    };
    const handleClick = (item) => {
        if (props.onItemsClick) {
            props.onItemsClick(item);
        }
    };
    return (React.createElement(React.Fragment, null,
        (properties.some(pr => pr.searchable) || props.pagination || props.customTools) &&
            React.createElement(Segment, { attached: props.attached },
                React.createElement("div", { className: "items-view-row items-view-flex-row" },
                    properties.some(pr => pr.searchable) &&
                        React.createElement("div", { className: "items-view-flex-item" },
                            React.createElement(Input, { style: { width: "20em" }, icon: 'search', placeholder: 'Search...', large: "true", value: props.searchKeyWord, className: 'left floated', onChange: (event, data) => {
                                    if (props.onSearchKeyWordChanged) {
                                        props.onSearchKeyWordChanged(data.value);
                                    }
                                    if (props.onActivePageChanged) {
                                        props.onActivePageChanged(1);
                                    }
                                } })),
                    props.customTools && (props.customTools.map(el => {
                        return (React.createElement("div", { className: "items-view-flex-item items-view-flex-item-middle" }, el));
                    })),
                    props.pagination &&
                        React.createElement("div", { className: "items-view-row items-view-flex-row" },
                            React.createElement("div", { className: "items-view-flex-item items-per-page-dropdown" },
                                React.createElement(ItemsPerPageDropdown, { noOfItemsToShowArray: props.noOfItemsToShowArray, setItemsPerPage: (newState) => {
                                        if (props.onNumberOfItemsShownChanged) {
                                            props.onNumberOfItemsShownChanged(newState);
                                        }
                                        if (props.onActivePageChanged) {
                                            props.onActivePageChanged(1);
                                        }
                                    }, selectedValue: props.numberOfItemsShown })),
                            React.createElement("div", { className: "items-view-flex-item" },
                                React.createElement(Pagination, { boundaryRange: 0, siblingRange: 1, size: 'small', activePage: props.activePage, totalPages: getNumberOfPages(), onPageChange: (e, pageInfo) => props.onActivePageChanged && props.onActivePageChanged(pageInfo.activePage) }))))),
        React.createElement(Table, { celled: true, compact: true, sortable: true, selectable: true },
            React.createElement(CustomTableHead, { properties: properties, onSortingColumnChanged: props.onSortingPropertyChanged, onSortingOrderChanged: props.onSortingDirectionChanged, itemActions: props.itemActions }),
            React.createElement(Table.Body, null, items.map((item, index) => (React.createElement(CustomTableRow, { key: index, item: item, properties: properties, handleRowClick: handleClick, itemActions: itemActions })))))));
};
