var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Button, Popup, Table } from "semantic-ui-react";
import { getItemContent } from "../../Utility/utility";
const CustomTableRow = (props) => {
    const { properties, item, itemActions } = props;
    return (React.createElement(Table.Row, null,
        properties
            .filter((property) => { var _a; return ((_a = property.displayPlaces) === null || _a === void 0 ? void 0 : _a.indexOf("table")) > -1; })
            .map((property, index) => {
            var hasColumnWidth = property.columnWidth;
            var tableCellProps = Object.assign(Object.assign({}, (hasColumnWidth && { width: property.columnWidth })), { key: index });
            return (React.createElement(Table.Cell, Object.assign({}, tableCellProps, { onDoubleClick: () => {
                    if (props.handleRowClick) {
                        props.handleRowClick(item);
                    }
                }, onMouseOver: (e) => {
                    e.target.style.cursor = "pointer";
                } }), getItemContent(props.item, [
                property.key,
                property.customRender,
                property.formaters,
                property.customRenderUseFormatData
            ])));
        }),
        itemActions &&
            React.createElement(Table.Cell, { textAlign: "center", key: "itemActions_cell", width: 1 },
                React.createElement(Button.Group, null, itemActions.map((actionProps, index) => {
                    var actionKey = actionProps.key + "_" + index;
                    const { displayOnCard, onClick, onHoverDescription } = actionProps, buttonProps = __rest(actionProps, ["displayOnCard", "onClick", "onHoverDescription"]);
                    return (onHoverDescription ? (React.createElement(Popup, { key: index, content: onHoverDescription, mouseEnterDelay: 500, mouseLeaveDelay: 500, trigger: React.createElement(Button, Object.assign({ key: actionKey, onClick: () => onClick(item) }, buttonProps)) })) : (React.createElement(Button, Object.assign({ key: actionKey, onClick: () => onClick(item) }, buttonProps))));
                })))));
};
export { CustomTableRow };
