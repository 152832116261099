import React, { useEffect, useState } from "react";
import { ItemsCardView } from "./ItemsCardView";
import { ItemsTableView } from "./ItemsTableView";
export const DynamicItemsViewer = (props) => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);
    return (React.createElement("div", { className: "dynamic-items-viewer" }, screenSize > props.cutoffScreenSize ? React.createElement(ItemsTableView, Object.assign({}, props)) :
        React.createElement(ItemsCardView, Object.assign({}, props))));
};
