import React, { useState } from "react";
import { MainContainer } from "./Layout/MainContainer";
import { AuthContext } from "./Auth/AuthContext";
import { NavigationContext } from "./Navigation/NavigationContext";
import { useLocation } from "react-router-dom";
import { RuntimeContext } from "./Runtime/RuntimeContext";
import { Loader, Dimmer } from "semantic-ui-react";
import { navigationSubject } from "./types";
export const EYAMApp = (props) => {
    const [pageTitle, setPageTitle] = useState("");
    const [language, setLanguage] = useState("");
    const [darkMode, toggleDarkMode] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const location = useLocation();
    let cView = null;
    let cRoles = [];
    for (let cRole in props.areaAccessRules) {
        cRoles.push(cRole);
    }
    if (props.userProfile == null) {
        cView = React.createElement(Dimmer, { active: true },
            React.createElement(Loader, { content: "Loading", inline: "centered", active: true }));
    }
    else {
        cView = (React.createElement(AuthContext.Provider, { value: { userProfile: props.userProfile, areaAccesRules: props.areaAccessRules } },
            React.createElement(NavigationContext.Provider, { value: { pageTitle: pageTitle, setTitle: (route) => { setPageTitle(route); }, navigationSubject: navigationSubject, onBackButtonClick: () => { navigationSubject.notify(); } } },
                React.createElement(RuntimeContext.Provider, { value: {
                        darkMode,
                        toggleDarkMode: () => toggleDarkMode(!darkMode),
                        language,
                        setLanguage: (language) => setLanguage(language),
                        isProcessing: isProcessing,
                        setIsProcessing: (isProcessing) => setIsProcessing(isProcessing)
                    } },
                    React.createElement(MainContainer, { navigationConfig: props.navigationConfig, areaAccessRules: props.areaAccessRules, authRoles: cRoles, homeRoute: props.homeRoute, renderNotAllowedPage: props.renderNotAllowedPage, notAllowedRoute: props.notAllowedRoute, renderNotFoundPage: props.renderNotFoundPage, notFoundRoute: props.notFoundRoute, logSceneBoundaryError: props.logSceneBoundaryError, renderSceneError: props.renderSceneError, appLogoPath: props.appLogoPath, onLogout: props.onLogout, sidebarWidth: props.sidebarWidth, isProcessing: isProcessing })))));
    }
    return cView;
};
