import React, { useContext } from "react";
export const RuntimeContext = React.createContext({
    language: "",
    setLanguage: (_) => { },
    darkMode: false,
    toggleDarkMode: () => { },
    isProcessing: false,
    setIsProcessing: () => { }
});
export const useRuntimeContext = () => useContext(RuntimeContext);
