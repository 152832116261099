var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useRef } from "react";
import { Button, Icon, Segment, Image, Card, Label } from "semantic-ui-react";
import NotificationService from "./../../Services/NotificationService";
export const ImageUploadControl = (props) => {
    const fileInputRef = useRef(null);
    const allowedFileTypes = ["image/png", "image/jpeg"];
    let handleImageChange = (e) => {
        e.preventDefault();
        let files = Array.from(e.target.files);
        files.forEach((file, i) => __awaiter(void 0, void 0, void 0, function* () {
            if (!allowedFileTypes.includes(file.type)) {
                NotificationService.showError('Please choose image files only. Supported image file types: JPEG / PNG.', "OK");
                return;
            }
            var resizedFile = yield resizeImg(file);
            var FR = new FileReader();
            FR.addEventListener("load", (e) => {
                var parts = e.target.result.split(";base64,");
                var base64 = parts[1];
                props.onImageUploaded(base64);
            });
            FR.readAsDataURL(resizedFile);
        }));
    };
    let handleRemoveImage = (index) => {
        props.onRemove(index);
    };
    let handleViewImage = (photoID) => {
        props.onView(photoID);
    };
    let resizeImg = (file) => __awaiter(void 0, void 0, void 0, function* () {
        let img = document.createElement("img");
        img.src = yield new Promise(resolve => {
            let reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.readAsDataURL(file);
        });
        yield new Promise(resolve => img.onload = resolve);
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let MAX_WIDTH = props.maxWidth;
        let MAX_HEIGHT = props.maxHeight;
        let width = img.naturalWidth;
        let height = img.naturalHeight;
        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        }
        else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        let result = yield new Promise(resolve => { canvas.toBlob(resolve, file.type, 0.95); });
        return result;
    });
    return (React.createElement("div", { className: "image-uploader" },
        !props.isReadonly
            && React.createElement("div", null,
                React.createElement(Button, { as: 'div', fluid: true, labelPosition: 'left', onClick: () => { if (!props.error) {
                        fileInputRef.current.click();
                    } } },
                    React.createElement(Label, { as: 'a', className: "fluid", basic: true, pointing: 'right', content: props.uploadBtnLabel, color: props.error ? 'red' : null }),
                    React.createElement(Button, { icon: "upload", color: props.error ? 'red' : null })),
                React.createElement("input", { hidden: true, ref: fileInputRef, type: "file", onChange: handleImageChange })),
        !props.isReadonly && props.errorMsg && props.error == true && React.createElement(Label, { basic: true, color: 'red', pointing: true, content: props.errorMsg }),
        React.createElement(Segment, { basic: true },
            React.createElement(Card.Group, { size: "medium" }, props.displayImg.map((imageData, index) => {
                var _a;
                return !imageData.isRemoved &&
                    (React.createElement(Card, null,
                        React.createElement(Image, { src: ((_a = imageData === null || imageData === void 0 ? void 0 : imageData.url) === null || _a === void 0 ? void 0 : _a.length) > 0 ? imageData === null || imageData === void 0 ? void 0 : imageData.url : "data:image/png;base64," + imageData.data }),
                        React.createElement(Card.Content, { extra: true },
                            React.createElement("div", { style: { display: "flex", flexDirection: "row", justifyContent: "space-between" } },
                                props.enableViewDetails &&
                                    ((imageData.photoID === null || imageData.photoID === "") ? React.createElement("div", null) : (React.createElement("div", { className: "view-btn", onClick: () => handleViewImage(imageData.photoID) },
                                        React.createElement("a", null,
                                            React.createElement(Icon, { name: 'expand arrows alternate', color: "green" }),
                                            " View Details ")))),
                                React.createElement("div", { className: "remove-btn", onClick: () => handleRemoveImage(index) },
                                    React.createElement("a", null,
                                        React.createElement(Icon, { name: 'remove circle', color: "red" }),
                                        " Remove "))))));
            })))));
};
