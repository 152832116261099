import React from "react";
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        this.props.logError ? this.props.logError(error, errorInfo) : console.error(error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            return this.props.renderError ? this.props.renderError() : "ERROR";
        }
        return this.props.children;
    }
}
