var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
export const DynamicDropdown = (props) => {
    const { options, maxDisplayed, onChange, search } = props, ddProps = __rest(props, ["options", "maxDisplayed", "onChange", "search"]);
    const maxNumberOfItems = (maxDisplayed != null && maxDisplayed > 0) ? maxDisplayed : 20;
    const renderSimpleDD = (options != null && options.length < maxNumberOfItems) ? true : false;
    const [searchQuery, setSearchQuery] = useState('');
    const [displayItems, setDisplayItems] = useState([]);
    useEffect(() => {
        if (options.length > 1) {
            let itms = options;
            if (props.value != null && props.value != '') {
                let currentOption = options === null || options === void 0 ? void 0 : options.find(io => io.value == props.value);
                if (currentOption) {
                    itms = [...new Set([currentOption, ...itms])];
                }
            }
            else if (props.defaultValue != null && props.defaultValue != '') {
                let defaultOption = options === null || options === void 0 ? void 0 : options.find(io => io.value == props.defaultValue);
                if (defaultOption) {
                    itms = [...new Set([defaultOption, ...itms])];
                }
            }
            setDisplayItems(sliceItems(itms));
        }
    }, [props.options, props.value]);
    useEffect(() => {
        var _a;
        let itms;
        if (((_a = props.options) === null || _a === void 0 ? void 0 : _a.length) < 1) {
            return;
        }
        if (searchQuery != '') {
            itms = options.filter(op => op.text.toLocaleString().toLocaleLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1);
        }
        else {
            itms = options;
        }
        if (props.value != null && props.value != '') {
            let currentOption = options === null || options === void 0 ? void 0 : options.find(io => io.value == props.value);
            if (currentOption) {
                itms = [...new Set([currentOption, ...itms])];
            }
        }
        else if (props.defaultValue != null && props.defaultValue != '') {
            let defaultOption = options === null || options === void 0 ? void 0 : options.find(io => io.value == props.defaultValue);
            if (defaultOption) {
                itms = [...new Set([defaultOption, ...itms])];
            }
        }
        setDisplayItems(sliceItems(itms));
    }, [searchQuery]);
    const handleSearchChange = (e, data) => {
        setSearchQuery(data.searchQuery);
    };
    const sliceItems = (items) => {
        let resultItems = [];
        if (items == null) {
            return resultItems;
        }
        if (items.length > maxNumberOfItems) {
            resultItems = items.slice(0, maxNumberOfItems - 1);
            resultItems.push({
                // use a Localization Key
                key: 'MoreOptions', value: 'MoreOptions', text: 'Other  options match...',
                disabled: true,
                active: false
            });
        }
        else {
            resultItems = items;
        }
        return resultItems;
    };
    const handleSearchPrevent = (options, value) => {
        return options;
    };
    const handleOnChange = (event, data) => {
        onChange(event, data);
        setSearchQuery('');
    };
    const handleOnClose = (event, data) => {
        setSearchQuery('');
    };
    return (React.createElement("div", null, renderSimpleDD ?
        (React.createElement(Dropdown, Object.assign({ options: options, search: search, onChange: handleOnChange, selectOnBlur: false, selectOnNavigation: false }, ddProps, { selection: true, fluid: true }))) :
        (React.createElement(Dropdown, Object.assign({ options: displayItems, onSearchChange: handleSearchChange, search: handleSearchPrevent, noResultsMessage: "No results founded.", 
            // placeholder={"Input text..."}
            onChange: handleOnChange, onClose: handleOnClose }, ddProps, { selectOnBlur: false, selectOnNavigation: false, selection: true, fluid: true })))));
};
