export class PhotoDetailBase {
    constructor() {
        this.probability = 0;
        this.tagId = "";
        this.tagName = "";
        this.tagType = "";
        this.left = 0;
        this.top = 0;
        this.width = 0;
        this.height = 0;
        this.description = "";
        this.EYDPhotoId = "";
    }
}
