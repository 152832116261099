import React from "react";
import { useEffect, useState } from "react";
import { Input, Pagination, Segment, Table } from "semantic-ui-react";
import { debounce } from "../../Utility/utility";
import { ItemsPerPageDropdown } from "./ItemsPerPageDropdown";
import { searchArray } from "../../Utility/SearchUtil";
import { sortArray } from "../../Utility/SortUtil";
import { CustomTableHead } from "./CustomTableHead";
import { CustomTableRow } from "./CustomTableRow";
export const ItemsTableView = (props) => {
    const { properties, items, itemActions } = props;
    const [filteredItems, setFilteredItems] = useState(items);
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [sortingProperty, setSortingProperty] = useState();
    const [sortingDirection, setSortingDirection] = useState("");
    useEffect(() => {
        let fItems = filterItems(items, searchKeyWord, props.properties);
        let sortedItems = sortItems(fItems);
        setFilteredItems(sortedItems);
    }, [items, searchKeyWord, sortingProperty, sortingDirection]);
    const filterItems = (items, query, itemDisplayConfigs) => {
        let fItems;
        if (query && query != "") {
            let filterDisplayConfigs = itemDisplayConfigs
                .filter((col) => col.searchable)
                .map((col) => {
                return [
                    col.key,
                    col.customRender,
                    col.formaters,
                    col.customRenderUseFormatData,
                    col.searchCriteria != null ? col.searchCriteria : "contain"
                ];
            });
            fItems = searchArray(items, query, filterDisplayConfigs, false);
        }
        else {
            fItems = items;
        }
        return fItems;
    };
    const sortItems = (itms) => {
        let sortableColumns = props.properties.filter((col) => col.sortable).map((col) => col.key);
        if (sortingProperty == null || sortingProperty == "" || sortableColumns.indexOf(sortingProperty) < 0) {
            return itms;
        }
        let displayConfig = props.properties.find((col) => col.key == sortingProperty);
        return sortArray(itms, sortingProperty, displayConfig.customRender, displayConfig.formaters, displayConfig.customRenderUseFormatData, sortingDirection == "ascending");
    };
    const getNumberOfPages = () => {
        return props.pagination ? Math.ceil(filteredItems.length / itemsPerPage) : 1;
    };
    const handleClick = (item) => {
        if (props.onItemsClick) {
            props.onItemsClick(item);
        }
    };
    return (React.createElement("div", { style: { position: "relative" } },
        (properties.some((pr) => pr.searchable) || props.pagination || props.customTools) && (React.createElement(Segment, { attached: props.attached },
            React.createElement("div", { className: "items-view-row items-view-flex-row" },
                React.createElement("div", { className: "items-view-flex-item" }, properties.some((pr) => pr.searchable) && (React.createElement(Input, { style: { width: "20em" }, icon: "search", placeholder: "Search...", large: "true", className: "left floated", onChange: debounce((event, data) => {
                        setSearchKeyWord(data.value);
                        if (props.onSearchKeyWordChanged) {
                            props.onSearchKeyWordChanged(data.value);
                        }
                        if (props.onActivePageChanged) {
                            props.onActivePageChanged(1);
                        }
                        setActivePage(1);
                    }) }))),
                props.customTools &&
                    props.customTools.map((el, index) => {
                        return React.createElement("div", { key: index, className: "items-view-flex-item" }, el);
                    }),
                React.createElement("div", { className: "items-view-row items-view-flex-row" },
                    props.pagination && (React.createElement("div", { className: "items-view-flex-item" },
                        React.createElement(ItemsPerPageDropdown, { noOfItemsToShowArray: props.noOfItemsToShowArray, setItemsPerPage: (newState) => {
                                setItemsPerPage(newState);
                                if (props.onNumberOfItemsShownChanged) {
                                    props.onNumberOfItemsShownChanged(newState);
                                }
                                if (props.onActivePageChanged) {
                                    props.onActivePageChanged(1);
                                }
                                setActivePage(1);
                            } }))),
                    props.pagination && (React.createElement("div", { className: "items-view-flex-item" }, props.pagination && (React.createElement(Pagination, { boundaryRange: 0, siblingRange: 1, size: "small", activePage: activePage, totalPages: getNumberOfPages(), onPageChange: (e, pageInfo) => setActivePage(pageInfo.activePage) })))))))),
        React.createElement(Table, { celled: true, compact: true, sortable: true, selectable: true },
            React.createElement(CustomTableHead, { properties: properties, onSortingColumnChanged: (sp) => {
                    setSortingProperty(sp);
                    if (props.onSortingPropertyChanged) {
                        props.onSortingPropertyChanged(sp);
                    }
                }, onSortingOrderChanged: (direction) => {
                    setSortingDirection(direction);
                    if (props.onSortingDirectionChanged) {
                        props.onSortingDirectionChanged(direction);
                    }
                }, itemActions: props.itemActions }),
            React.createElement(Table.Body, null, filteredItems.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage).map((item, index) => (React.createElement(CustomTableRow, { key: index, item: item, properties: properties, handleRowClick: handleClick, itemActions: itemActions })))))));
};
