import React from "react";
import { AuthContext } from "../Auth/AuthContext";
import { Icon, Grid, Header, Image } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { deviceMediaQuery } from "../Utility/utility";
import { useNavigationContext } from "../Navigation/NavigationContext";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
const AppHeader = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: deviceMediaQuery });
    const navigationContext = useNavigationContext();
    const pageTitle = navigationContext.pageTitle;
    const pageTitleHeader = (pageTitle && pageTitle != "HOME") && React.createElement("div", { className: "asset-manager-header__tablet-page-title" }, pageTitle);
    const location = useLocation();
    const onBackButtonClick = () => {
        if (navigationContext.onBackButtonClick() != null) {
            navigationContext.navigationSubject.notify();
        }
    };
    return (React.createElement(AuthContext.Consumer, null, (authData) => {
        return (React.createElement("div", { className: "asset-manager-header" }, isTabletOrMobile ? (React.createElement(Grid, { verticalAlign: "middle", columns: "equal" },
            React.createElement(Grid.Row, null,
                React.createElement(Grid.Column, { floated: "left", width: 10 },
                    React.createElement("div", { className: "asset-manager-header__brand-container" },
                        React.createElement(NavLink, { to: props.homeRoute },
                            React.createElement(Image, { className: "asset-manager-header__app-logo", src: props.appLogoPath })),
                        "                                             ",
                        React.createElement("div", { className: "asset-manager-header__title-container" },
                            React.createElement("div", { className: "asset-manager-header__title-top" }, "COInS"),
                            React.createElement("div", { className: "asset-manager-header__title-bottom" }, "Field Asset Module")))),
                React.createElement(Grid.Column, { floated: "right", width: 6, textAlign: "right" },
                    location.pathname != props.homeRoute && React.createElement(Icon, { size: "large", name: "arrow left", className: "asset-manager-header__nav-button", onClick: () => { onBackButtonClick(); } }),
                    React.createElement(Icon, { size: "large", onClick: props.onNavClick, name: "bars", className: "asset-manager-header__nav-button" }))),
            pageTitleHeader &&
                React.createElement(Grid.Row, { className: "asset-manager-header__mobile-page-title-container" },
                    React.createElement(Grid.Column, { floated: "left", className: "asset-manager-header__mobile-page-title" },
                        React.createElement("div", { style: { marginBottom: "1.5rem" }, className: "ui horizontal divider" }, pageTitle))))) : (React.createElement(Grid, { verticalAlign: "middle", columns: "equal" },
            React.createElement(Grid.Row, null,
                React.createElement(Grid.Column, { className: "asset-manager-header__title" }, pageTitleHeader),
                React.createElement(Grid.Column, { textAlign: "right", className: "asset-manager-header__user-info", floated: "right" },
                    React.createElement(Header, { size: "small" },
                        React.createElement(Header.Content, null, authData.userProfile.displayName),
                        React.createElement(Icon, { name: "user circle", fitted: true, size: "big" }))))))));
    }));
};
export default AppHeader;
