import React from "react";
import { Button, Dimmer, Loader, Message, Modal } from "semantic-ui-react";
import { DynamicForm } from "../..";
export const DynamicModalForm = (props) => {
    return (React.createElement("div", null,
        React.createElement(Modal, { className: "form-dialog-modal", onClose: props.onCloseHandler, onOpen: props.onOpenHandler, open: props.open, closeOnDocumentClick: false, closeOnEscape: false, closeOnDimmerClick: false },
            React.createElement(Modal.Header, null, props.title),
            React.createElement(Modal.Content, null, props.isProcessing ? (React.createElement(Dimmer, { active: true, inverted: true },
                React.createElement(Loader, { content: "Loading", inline: "centered", active: true }))) : (React.createElement(React.Fragment, null,
                props.customContent != null ? props.customContent : '',
                props.errorMessage && React.createElement(Message, { negative: true },
                    React.createElement(Message.Header, null, "Error"),
                    React.createElement("p", null, props.errorMessage)),
                React.createElement(DynamicForm, { groups: props.modalFormGroups })))),
            React.createElement(Modal.Actions, null, props.formActions &&
                props.formActions.map((action) => {
                    return (React.createElement(Button, Object.assign({}, action)));
                })))));
};
