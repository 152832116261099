import React from "react";
import { Dropdown } from "semantic-ui-react";
export const ItemsPerPageDropdown = (props) => {
    var _a;
    const onChange = (event, data) => {
        props.setItemsPerPage(data.value);
    };
    const defaultValue = props.selectedValue ? undefined : (_a = props.noOfItemsToShowArray[0]) === null || _a === void 0 ? void 0 : _a.value;
    return (React.createElement(Dropdown, { onChange: onChange, options: props.noOfItemsToShowArray, selection: true, defaultValue: defaultValue, value: props.selectedValue }));
};
