import React from "react";
import { useEffect, useState } from "react";
import { Icon, Input, Segment } from "semantic-ui-react";
import { debounce } from "../../Utility/utility";
import { ItemsPerPageDropdown } from "./ItemsPerPageDropdown";
import { searchArray } from "../../Utility/SearchUtil";
import { sortArray } from "../../Utility/SortUtil";
import { SortingPropertiesDropdown } from "./SortingPropertiesDropdown";
import { ItemCard } from "./ItemCard";
export const ItemsCardView = (props) => {
    const { properties, items } = props;
    const [activePage, setActivePage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [filteredItems, setFilteredItems] = useState(items);
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [arrowDirection, setArrowDirection] = useState("arrow down");
    const [sortingProperty, setSortingProperty] = useState();
    const [sortingDirection, setSortingDirection] = useState("");
    useEffect(() => {
        if (props.onSortingDirectionChanged) {
            props.onSortingDirectionChanged(sortingDirection);
        }
        if (props.onSortingPropertyChanged) {
            props.onSortingPropertyChanged(sortingProperty);
        }
    }, [sortingProperty, sortingDirection]);
    useEffect(() => {
        let fItems = filterItems(items, searchKeyWord, props.properties);
        let sortedItems = sortItems(fItems);
        setFilteredItems(sortedItems);
    }, [items, searchKeyWord, sortingProperty, sortingDirection]);
    const filterItems = (items, query, itemDisplayConfigs) => {
        let fItems;
        if (query && query != "") {
            let filterDisplayConfigs = itemDisplayConfigs
                .filter((col) => col.searchable)
                .map((col) => {
                return [
                    col.key,
                    col.customRender,
                    col.formaters,
                    col.customRenderUseFormatData,
                    col.searchCriteria != null ? col.searchCriteria : "contain"
                ];
            });
            fItems = searchArray(items, query, filterDisplayConfigs, false);
        }
        else {
            fItems = items;
        }
        return fItems;
    };
    const sortItems = (itms) => {
        let sortableColumns = props.properties.filter((col) => col.sortable).map((col) => col.key);
        if (sortingProperty == null || sortingProperty == "" || sortableColumns.indexOf(sortingProperty) < 0) {
            return itms;
        }
        let displayConfig = props.properties.find((col) => col.key == sortingProperty);
        return sortArray(itms, sortingProperty, displayConfig.customRender, displayConfig.formaters, displayConfig.customRenderUseFormatData, sortingDirection == "ascending");
    };
    const getNumberOfPages = () => {
        return props.pagination ? Math.ceil(filteredItems.length / itemsPerPage) : 1;
    };
    const handleClick = (item) => {
        if (props.onItemsClick) {
            props.onItemsClick(item);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Segment, { attached: props.attached },
            React.createElement("div", { className: "items-view-row items-view-flex-row" },
                React.createElement("div", { className: "items-view-flex-item" },
                    React.createElement(SortingPropertiesDropdown, { setSortingOrder: (newState) => setArrowDirection(newState), setSortingProperty: (newState) => setSortingProperty(newState), properties: properties })),
                React.createElement("div", { className: "items-view-flex-item" },
                    React.createElement(Icon, { name: arrowDirection, size: "big", onClick: () => {
                            if (arrowDirection === "arrow down") {
                                setArrowDirection("arrow up");
                                setSortingDirection("ascending");
                            }
                            else {
                                setArrowDirection("arrow down");
                                setSortingDirection("descending");
                            }
                        }, onMouseOver: (e) => {
                            e.target.style.cursor = "pointer";
                        } }))),
            (properties.some((pr) => pr.searchable) || props.pagination || props.customTools) && (React.createElement("div", { className: "items-view-row items-view-flex-row" },
                React.createElement("div", { className: "items-view-flex-item" },
                    React.createElement(ItemsPerPageDropdown, { noOfItemsToShowArray: props.noOfItemsToShowArray, setItemsPerPage: (newState) => {
                            setItemsPerPage(newState);
                            if (props.onNumberOfItemsShownChanged) {
                                props.onNumberOfItemsShownChanged(newState);
                            }
                            if (props.onActivePageChanged) {
                                props.onActivePageChanged(1);
                            }
                            setActivePage(1);
                        } })),
                props.pagination && getNumberOfPages() >= 2 && (React.createElement("div", { className: "items-view-flex-item" },
                    React.createElement(Icon, { size: "large", name: "angle left", onClick: () => {
                            if (activePage > 1) {
                                if (props.onActivePageChanged) {
                                    props.onActivePageChanged(activePage - 1);
                                }
                                setActivePage(activePage - 1);
                            }
                        }, onMouseOver: (e) => {
                            e.target.style.cursor = "pointer";
                        } }),
                    activePage,
                    " / ",
                    props.pagination ? Math.ceil(filteredItems.length / itemsPerPage) : 1,
                    React.createElement(Icon, { size: "large", name: "angle right", onClick: () => {
                            if (activePage < getNumberOfPages()) {
                                if (props.onActivePageChanged) {
                                    props.onActivePageChanged(activePage + 1);
                                }
                                setActivePage(activePage + 1);
                            }
                        }, onMouseOver: (e) => {
                            e.target.style.cursor = "pointer";
                        } }))))),
            React.createElement("div", { className: "items-view-row items-view-flex-item" },
                React.createElement(Input, { fluid: true, icon: "search", placeholder: "Search...", onChange: debounce((event, data) => {
                        setSearchKeyWord(data.value);
                        if (props.onSearchKeyWordChanged) {
                            props.onSearchKeyWordChanged(data.value);
                        }
                        if (props.onActivePageChanged) {
                            props.onActivePageChanged(1);
                        }
                        setActivePage(1);
                    }) })),
            React.createElement("div", { className: "items-view-row items-view-flex-item" }, props.customTools &&
                props.customTools.map((el) => {
                    return React.createElement("div", { className: "items-view-flex-item" }, el);
                }))),
        filteredItems.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage).map((item, index) => {
            return React.createElement(ItemCard, { item: item, key: index, properties: properties, itemActions: props.itemActions });
        })));
};
