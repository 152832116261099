import React, { useState, useEffect, useRef } from "react";
import { Icon, Button } from "semantic-ui-react";
export const ScannerInput = (props) => {
    const [scannedItemID, setScannedItemID] = useState("");
    const [autoFocus, setAutoFocus] = useState(false);
    const [forceFocusIntId, setForceFocusIntId] = useState(null);
    const comandInput = useRef(null);
    const checkEnter = (event) => {
        if (event.keyCode == 13) {
            props.onItemScanned(scannedItemID);
            setScannedItemID("");
        }
    };
    useEffect(() => {
        if (autoFocus)
            toggleAutoFocus();
    }, []);
    const startForceFocusInterval = () => {
        if (forceFocusIntId != null) {
            window.clearInterval(forceFocusIntId);
        }
        let fintId = window.setInterval(() => {
            if (comandInput && comandInput.current != null) {
                comandInput.current.focus();
            }
        }, 400);
        setForceFocusIntId(fintId);
    };
    const stopForceFocusInterval = () => {
        if (forceFocusIntId != null) {
            window.clearInterval(forceFocusIntId);
            if (comandInput && comandInput.current != null) {
                comandInput.current.blur();
            }
        }
    };
    const toggleAutoFocus = () => {
        if (autoFocus) {
            stopForceFocusInterval();
        }
        else {
            startForceFocusInterval();
        }
        setAutoFocus(!autoFocus);
    };
    return (React.createElement("div", { className: "scan-entry" },
        React.createElement(Icon, { onClick: () => { toggleAutoFocus(); }, name: "dot circle", className: "scan-entry__scan-icon" + (autoFocus ? " active-scan" : "") }),
        React.createElement("input", { className: "ui input scan-entry__scan-input", placeholder: props.inputPlaceholderText, value: scannedItemID, onKeyUp: checkEnter, ref: comandInput, onChange: (event) => { setScannedItemID(event.target.value); } }),
        React.createElement(Button, { floated: "right", type: "submit", primary: true, content: "Submit", onClick: (event) => {
                event.preventDefault();
                props.onItemScanned(scannedItemID);
                setScannedItemID("");
            }, positive: true, className: "scan-entry__submit-btn" })));
};
