class NavigationSubject {
    constructor() {
        this.observer = null;
    }
    attach(observerToAttach) {
        this.observer = observerToAttach;
    }
    detach() {
        this.observer = null;
    }
    notify() {
        if (this.observer != null) {
            this.observer();
        }
    }
}
const navigationSubject = new NavigationSubject();
export { navigationSubject, NavigationSubject };
