import React, { Fragment } from "react";
import { Form, Tab, Label, Input, Dropdown, TextArea, Select } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { isNullOrUndefined } from "../../Utility/utility";
import { DynamicDropdown } from "../DynamicDropdown/DynamicDropdown";
export const DynamicForm = (props) => {
    const getFormFieldValues = (formField) => {
        let values = new Array();
        let fieldValue = "";
        if (!isNullOrUndefined(formField.value)) {
            fieldValue = formField.value;
        }
        else if (!isNullOrUndefined(formField.defaultValue)) {
            fieldValue = formField.defaultValue;
        }
        switch (formField.control) {
            case Input:
            case TextArea:
                if ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.toString().length) > 0) {
                    values.push(fieldValue.toString());
                }
                break;
            case DateInput:
                if ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.length) > 0) {
                    values.push(fieldValue.toString().substr(0, 10));
                }
                break;
            case Dropdown:
            case Select:
            case DynamicDropdown:
                if (formField.multiple == true) {
                    let fieldValues = [...fieldValue];
                    let fieldOptions = [...formField.options];
                    fieldValues.forEach(val => { var _a; return values.push((_a = fieldOptions.find(o => o.value == val)) === null || _a === void 0 ? void 0 : _a.text); });
                }
                else {
                    let fieldOptions = [...formField.options];
                    let option = fieldOptions.find(o => o.value == fieldValue);
                    if (!isNullOrUndefined(option)) {
                        values.push(option.text);
                    }
                }
                break;
        }
        return values;
    };
    const renderFormFields = (formFields) => {
        return (React.createElement(Fragment, null, formFields === null || formFields === void 0 ? void 0 : formFields.map((formField, index) => {
            if (formField.disabled == true) {
                let values = getFormFieldValues(formField);
                return (React.createElement(Form.Field, { key: "field " + index },
                    React.createElement("label", null, formField.label),
                    (values === null || values === void 0 ? void 0 : values.length) > 0 ? values.map((value, indexV) => (React.createElement(Label, { basic: true, size: "large", key: "label " + indexV }, value))) : React.createElement(Label, { basic: true, size: "large" }, "-")));
            }
            else {
                return (React.createElement(Form.Field, Object.assign({}, formField, { key: "field " + index })));
            }
        })));
    };
    const renderFormFieldGroups = () => {
        let groups = props.groups.map((group, index) => {
            var _a, _b;
            let formFields = renderFormFields(group.formFields);
            return (React.createElement(Form.Group, Object.assign({ key: "group " + index, grouped: (_b = (_a = group.groupProps) === null || _a === void 0 ? void 0 : _a.grouped) !== null && _b !== void 0 ? _b : true }, group.groupProps),
                " ",
                formFields));
        });
        return groups;
    };
    const renderTabPanes = () => {
        let panes = props.groups.map((pane, index) => {
            var _a;
            let formFields = (_a = pane.formFields) === null || _a === void 0 ? void 0 : _a.map((formField, fieldIndex) => {
                if (formField.error) {
                    pane.tabPane = Object.assign(Object.assign({}, pane.tabPane), { className: "error" });
                }
                return React.createElement(Form.Field, Object.assign({ key: "field " + fieldIndex }, formField));
            });
            return {
                menuItem: Object.assign(Object.assign({}, pane.tabPane), { key: "tab " + index }),
                render: () => React.createElement(Tab.Pane, { key: "tab " + index }, formFields)
            };
        });
        return React.createElement(Tab, { panes: panes });
    };
    if (props.groups && props.groups.length) {
        return React.createElement(Form, { className: "form-fields" }, props.groups.length > 1 ?
            (props.groups && props.groups.length > 1 ? renderTabPanes() : renderFormFieldGroups()) : renderFormFields(props.groups[0].formFields));
    }
    else {
        return React.createElement(Form, { className: "form-fields" });
    }
};
