import AppHeader from "./AppHeader";
import { useMediaQuery } from "react-responsive";
import NavigationMenu from "./NavigationMenu";
import { deviceMediaQuery } from "../Utility/utility";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";
import { AuthSwitch } from "../Auth/AuthSwitch";
import { Loader } from "semantic-ui-react";
import { ErrorBoundary } from "../Utility/ErrorBoundary";
import { LoaderWrapper } from "../types";
export const MainContainer = (props) => {
    const [isNavMenuVisible, setVisible] = React.useState(false);
    const isTabletOrMobile = useMediaQuery({ query: deviceMediaQuery });
    return (React.createElement(AuthContext.Consumer, null, (authData) => {
        let defaultRoute = props.homeRoute;
        return (React.createElement("div", { className: "main-container " + (isTabletOrMobile ? "device-layout" : "desktop-layout") },
            React.createElement(NavigationMenu, { sidebarWidth: props.sidebarWidth, navigationConfig: props.navigationConfig, visible: isNavMenuVisible, onHide: () => setVisible(false), onLogout: props.onLogout, authAccessRules: props.areaAccessRules, authRoles: props.authRoles }),
            React.createElement("div", { className: "main-content" },
                React.createElement(AppHeader, { onNavClick: () => setVisible(true), appLogoPath: props.appLogoPath, homeRoute: props.homeRoute }),
                React.createElement("div", { className: "scene" },
                    React.createElement(ErrorBoundary, { logError: props.logSceneBoundaryError, renderError: props.renderSceneError },
                        React.createElement(Suspense, { fallback: React.createElement(Loader, { active: true, inline: true }) },
                            React.createElement(Routes, null,
                                defaultRoute && defaultRoute != "" && defaultRoute != "/" && React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: defaultRoute }) }),
                                props.navigationConfig.map((navGroup, index) => {
                                    let key = "group-" + index;
                                    return navGroup.items.map((item, index) => {
                                        if (item.menuOnly) {
                                            return;
                                        }
                                        return (React.createElement(Route, { key: key + index, path: item.path, element: React.createElement(ErrorBoundary, { key: "error-bound-" + index, logError: props.logSceneBoundaryError, renderError: props.renderSceneError },
                                                React.createElement(AuthSwitch, { renderAllowed: () => React.createElement(LoaderWrapper, { inverted: true, active: props.isProcessing }, item.view), renderDenied: props.renderNotAllowedPage, area: navGroup.area, roles: authData.userProfile.roles, areaAccessRules: props.areaAccessRules })) }));
                                    });
                                }),
                                React.createElement(Route, { path: props.notAllowedRoute, element: props.renderNotAllowedPage() }),
                                React.createElement(Route, { element: props.renderNotFoundPage() }))))))));
    }));
};
