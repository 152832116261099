import React, { useEffect, useState } from "react";
import { Button, Icon, Input, List, Segment } from "semantic-ui-react";
export const DualListBox = (props) => {
    const [leftOptions, setLeftOptions] = useState([]);
    const [rightOptions, setRightOptions] = useState([]);
    const [selectedRightItem, setSelectedRightItem] = useState(null);
    const [selectedLeftItem, setSelectedLeftItem] = useState(null);
    const [leftSearch, setLeftSearch] = useState("");
    const [rightSearch, setRightSearch] = useState("");
    useEffect(() => {
        let leftOptions = props.options.filter((o) => (props.selectedOptions || []).indexOf(o.key) === -1);
        if (leftSearch) {
            leftOptions = leftOptions.filter((o) => o.text.toLowerCase().includes(leftSearch.toLowerCase()) || String(o.key) === leftSearch);
        }
        setLeftOptions(leftOptions.sort(compare));
    }, [props.selectedOptions, leftSearch]);
    useEffect(() => {
        var _a;
        let rightOptions = [];
        if (((_a = props.selectedOptions) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            for (let option of props.selectedOptions) {
                let boxOption = props.options.find((o) => o.key === option);
                if (boxOption) {
                    rightOptions.push(boxOption);
                }
            }
        }
        if (rightSearch) {
            rightOptions = rightOptions.filter((o) => o.text.toLowerCase().includes(rightSearch.toLowerCase()) || String(o.key) === rightSearch);
        }
        setRightOptions(rightOptions);
    }, [props.selectedOptions, rightSearch]);
    const compare = (a, b) => {
        const first = a.text.toLowerCase();
        const second = b.text.toLowerCase();
        return first > second ? 1 : -1;
    };
    const renderLeftBoxOptions = () => {
        const elements = leftOptions.map((o) => {
            const { key } = o;
            return (React.createElement(List.Item, { as: "a", active: selectedLeftItem === key, onClick: () => setSelectedLeftItem(key), key: key, onDoubleClick: addItem, style: {
                    fontWeight: selectedLeftItem === key ? 'bold' : 'normal'
                } }, o.text));
        });
        return (React.createElement(List, { className: "dual-list-box-options", link: true, size: "large" }, elements));
    };
    const renderRightBoxOptions = () => {
        const elements = rightOptions.map((o) => {
            const { text, key } = o;
            return (React.createElement(List.Item, { as: "a", active: selectedRightItem === key, onClick: () => setSelectedRightItem(key), key: key, onDoubleClick: removeItem, style: {
                    fontWeight: selectedRightItem === key ? 'bold' : 'normal'
                } }, text));
        });
        return (React.createElement(List, { className: "dual-list-box-options", link: true, size: "large" }, elements));
    };
    const addItem = () => {
        if (!selectedLeftItem || leftOptions.findIndex((o) => o.key === selectedLeftItem) === -1) {
            return;
        }
        props.onChange((props.selectedOptions || []).concat(selectedLeftItem));
    };
    const addAllItems = () => {
        const items = (props.selectedOptions || []).concat(leftOptions.map((o) => o.key));
        props.onChange(items);
    };
    const removeItem = () => {
        if (!selectedRightItem || rightOptions.findIndex((o) => o.key === selectedRightItem) === -1) {
            return;
        }
        let selected = (props.selectedOptions || []).filter((o) => o !== selectedRightItem);
        props.onChange(selected);
    };
    const removeAllItems = () => {
        let selected = (props.selectedOptions || []).filter((key) => rightOptions.findIndex((o) => o.key === key) === -1);
        props.onChange(selected);
    };
    return (React.createElement(Segment.Group, { horizontal: true },
        React.createElement(Segment, { basic: true },
            React.createElement(Input, { className: "input-only-bottom-border", icon: "search", placeholder: "Search...", large: "true", onChange: (_, data) => setLeftSearch(data.value) }),
            renderLeftBoxOptions()),
        React.createElement(Segment, { basic: true, className: "dual-list-button-wrapper" },
            React.createElement(Button.Group, { size: "tiny", vertical: true },
                React.createElement(Button, { icon: true, onClick: addAllItems },
                    React.createElement(Icon, { size: "large", name: "angle double right" })),
                React.createElement(Button, { icon: true, onClick: addItem },
                    React.createElement(Icon, { size: "large", name: "angle right" })),
                React.createElement(Button, { icon: true, onClick: removeItem },
                    React.createElement(Icon, { size: "large", name: "angle left" })),
                React.createElement(Button, { icon: true, onClick: removeAllItems },
                    React.createElement(Icon, { size: "large", name: "angle double left" })))),
        React.createElement(Segment, { basic: true },
            React.createElement(Input, { className: "input-only-bottom-border", icon: "search", placeholder: "Search...", large: "true", onChange: (_, data) => setRightSearch(data.value) }),
            renderRightBoxOptions())));
};
