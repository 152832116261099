import React, { useEffect } from "react";
import { useState } from "react";
import { Table } from "semantic-ui-react";
const CustomTableHead = (props) => {
    const [state, setState] = useState({
        column: null,
        direction: null
    });
    const { column, direction } = state;
    const onHeaderClickHandler = (state, actionColumn, isSortable) => {
        if (isSortable) {
            if (state.column === actionColumn) {
                setState({ column: actionColumn, direction: state.direction === "ascending" ? "descending" : "ascending" });
            }
            else {
                setState({ column: actionColumn, direction: "ascending" });
            }
        }
    };
    useEffect(() => {
        if (props.onSortingColumnChanged) {
            props.onSortingColumnChanged(state.column);
        }
        if (props.onSortingOrderChanged) {
            props.onSortingOrderChanged(state.direction);
        }
    }, [state]);
    return (React.createElement(Table.Header, null,
        React.createElement(Table.Row, null,
            props.properties
                .filter((property) => { var _a; return ((_a = property.displayPlaces) === null || _a === void 0 ? void 0 : _a.indexOf("table")) > -1; })
                .map((property, index) => (React.createElement(Table.HeaderCell, { key: index, sorted: property.sortable && column === property.key ? direction : null, onClick: () => {
                    onHeaderClickHandler(state, property.key, property.sortable);
                } }, property.label))),
            props.itemActions && React.createElement(Table.HeaderCell, { key: "itemActions", width: 1 }))));
};
export { CustomTableHead };
