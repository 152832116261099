import React from "react";
import { useState } from "react";
import { Dropdown } from "semantic-ui-react";
const SortingPropertiesDropdown = (props) => {
    const [text, setText] = useState("Sort by ");
    return (React.createElement(Dropdown, { text: text },
        React.createElement(Dropdown.Menu, null, props.properties
            .filter((col) => col.sortable)
            .map((item, index) => {
            return (React.createElement(Dropdown.Item, { key: index, onClick: (event, data) => {
                    if (props.setSortingProperty) {
                        props.setSortingProperty(data.value);
                    }
                    if (props.setSortingOrder) {
                        props.setSortingOrder("arrow down");
                    }
                    setText("Sort by " + data.text);
                }, text: item.label, value: item.key }));
        }))));
};
export { SortingPropertiesDropdown };
