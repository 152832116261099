import React, { Fragment } from "react";
import { Icon, Menu, Sidebar, Header, Image } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { deviceMediaQuery, trimString } from "../Utility/utility";
import { useAuthContext } from "../Auth/AuthContext";
import { isAllowed } from "../Auth/AuthSwitch";
const NavigationMenu = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: deviceMediaQuery });
    const authContext = useAuthContext();
    let renderContent = () => {
        let content;
        if (isTabletOrMobile) {
            content = (React.createElement(Sidebar, { as: Menu, style: { width: props.sidebarWidth }, direction: "right", animation: "overlay", inverted: true, onHide: props.onHide, vertical: true, visible: props.visible }, renderMenuItems()));
        }
        else {
            content = (React.createElement(Menu, { inverted: true, vertical: true, fixed: "left" }, renderMenuItems()));
        }
        return content;
    };
    let renderMenuItems = () => {
        return (React.createElement(Fragment, null,
            React.createElement(Menu.Item, null, isTabletOrMobile ? (React.createElement("div", { className: "user-info" },
                React.createElement(Header, { size: "small" },
                    React.createElement(Icon, { fitted: true, name: "user circle", size: "big", inverted: true }),
                    React.createElement(Header.Content, { className: "asset-manager-header__inverted" }, authContext.userProfile.displayName)))) : (React.createElement(Image, { className: "asset-manager-header__ey-logo", src: "/images/logo_simple_xs.png" }))),
            props.navigationConfig.map((mg, index) => {
                var _a, _b;
                let key = "menu-group2-" + index;
                if (!isAllowed(((_a = authContext === null || authContext === void 0 ? void 0 : authContext.userProfile) === null || _a === void 0 ? void 0 : _a.roles) ? (_b = authContext === null || authContext === void 0 ? void 0 : authContext.userProfile) === null || _b === void 0 ? void 0 : _b.roles : [], props.authAccessRules, mg.area)) {
                    return;
                }
                return (React.createElement(Menu.Item, { key: key, exact: "true" },
                    React.createElement(Menu.Header, null, mg.name),
                    React.createElement(Menu.Menu, null, mg.items.map((mgItem, index) => {
                        if (mgItem.routeOnly) {
                            return;
                        }
                        let viewTitle = trimString(mgItem.viewTitle, 20);
                        return (React.createElement(NavLink, { to: mgItem.path, key: key + "__menu-item-" + index, className: "item", onClick: props.onHide }, viewTitle));
                    }))));
            }),
            React.createElement(Menu.Item, { onClick: props.onLogout }, "Log out")));
    };
    return React.createElement("div", { className: "navigation-menu" }, renderContent());
};
export default NavigationMenu;
