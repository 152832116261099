class FieldDependencyNode {
}
class FieldDependencyEdge {
}
export class FieldDependencyGraph {
    constructor() {
        this._adjList = new Map();
        this._transpAdjList = new Map();
        this._adjDepMatr = new Map();
        this._transpAdjDepMatr = new Map();
        this._nodeList = {};
        this.initialize = (dependencyList) => {
            let nodeListMap = new Map();
            let edgeList = [];
            let transEdgeList = [];
            dependencyList.forEach(dep => {
                if (!nodeListMap.has(dep.customFieldID)) {
                    nodeListMap.set(dep.customFieldID, dep.customFieldID);
                }
                if (!nodeListMap.has(dep.dependsOnCustomFieldID)) {
                    nodeListMap.set(dep.dependsOnCustomFieldID, dep.dependsOnCustomFieldID);
                }
                if (!this._adjDepMatr.has(dep.customFieldID)) {
                    this._adjDepMatr.set(dep.customFieldID, new Map());
                }
                if (!this._adjDepMatr.get(dep.customFieldID).has(dep.dependsOnCustomFieldID)) {
                    this._adjDepMatr.get(dep.customFieldID).set(dep.dependsOnCustomFieldID, []);
                }
                this._adjDepMatr.get(dep.customFieldID).get(dep.dependsOnCustomFieldID).push(dep);
                if (!this._transpAdjDepMatr.has(dep.dependsOnCustomFieldID)) {
                    this._transpAdjDepMatr.set(dep.dependsOnCustomFieldID, new Map());
                }
                if (!this._transpAdjDepMatr.get(dep.dependsOnCustomFieldID).has(dep.customFieldID)) {
                    this._transpAdjDepMatr.get(dep.dependsOnCustomFieldID).set(dep.customFieldID, []);
                }
                this._transpAdjDepMatr.get(dep.dependsOnCustomFieldID).get(dep.customFieldID).push(dep);
                var depEdge = new FieldDependencyEdge();
                var transDepEdge = new FieldDependencyEdge();
                if (!edgeList.some(e => e.from == dep.customFieldID && e.to == dep.dependsOnCustomFieldID)) {
                    depEdge.from = dep.customFieldID;
                    depEdge.to = dep.dependsOnCustomFieldID;
                    if (!this._adjList.has(dep.customFieldID)) {
                        this._adjList.set(dep.customFieldID, []);
                    }
                    this._adjList.get(dep.customFieldID).push(dep.dependsOnCustomFieldID);
                    transDepEdge.to = dep.customFieldID;
                    transDepEdge.from = dep.dependsOnCustomFieldID;
                    if (!this._transpAdjList.has(dep.dependsOnCustomFieldID)) {
                        this._transpAdjList.set(dep.dependsOnCustomFieldID, []);
                    }
                    this._transpAdjList.get(dep.dependsOnCustomFieldID).push(dep.customFieldID);
                    edgeList.push(depEdge);
                    transEdgeList.push(transDepEdge);
                }
                else {
                    //TODO: figure out to handle mutiple dependecy types on the same edge
                }
            });
            for (var k of nodeListMap.values()) {
                this._nodeList[k] = new FieldDependencyNode();
                if (!this._transpAdjList.has(k)) {
                    this._transpAdjList.set(k, []);
                }
                if (!this._adjList.has(k)) {
                    this._adjList.set(k, []);
                }
            }
        };
        this.topo = () => {
            this.topologicalSort(this._adjList);
        };
        this.topoReverse = () => {
            this.topologicalSort(this._transpAdjList);
        };
        this.topologicalSort = (adjList) => {
            let indegree = new Map();
            for (let k of adjList.keys()) {
                indegree.set(k, 0);
            }
            for (let adLst of adjList.values()) {
                for (let node = 0; node < adLst.length; node++) {
                    let cVal = indegree.get(adLst[node]);
                    indegree.set(adLst[node], ++cVal);
                }
            }
            let q = [];
            for (let k of indegree.keys()) {
                if (indegree.get(k) == 0) {
                    q.push(k);
                }
            }
            let cnt = 0;
            let topOrder = [];
            while (q.length != 0) {
                let u = q.shift();
                topOrder.push(u);
                let cAdjList = adjList.get(u);
                for (let node = 0; node < cAdjList.length; node++) {
                    let ctp = indegree.get(cAdjList[node]);
                    ctp--;
                    indegree.set(cAdjList[node], ctp);
                    if (ctp == 0) {
                        q.push(cAdjList[node]);
                    }
                }
                cnt++;
            }
            // Check if there was a cycle
            if (cnt != adjList.size) {
                console.log("Graph has cycle");
                return;
            }
            return topOrder;
        };
        this.getFormEffectOrder = () => {
            return this.topologicalSort(this._transpAdjList);
        };
        this.triggerEffectsForField = (fieldID, effectFn) => {
            if (this._transpAdjList.has(fieldID)) {
                var affectedNodes = this._transpAdjList.get(fieldID);
                for (let node of affectedNodes) {
                    if (this._transpAdjDepMatr.has(fieldID) && this._transpAdjDepMatr.get(fieldID).has(node)) {
                        let needsCascade = effectFn(node, fieldID, this._transpAdjDepMatr.get(fieldID).get(node));
                        if (needsCascade) {
                            this.triggerEffectsForField(node, effectFn);
                        }
                    }
                }
            }
        };
    }
}
