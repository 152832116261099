import { DynamicEntityFieldType, DynamicEntityQueryFilter, DynamicEntityQueryFilterType, CustomFieldBase } from "eyam-webui-models";
import { useEffect, useState } from "react";
import { Button, Dropdown, Input, Label, Segment } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import React from "react";
import { useTranslation } from "react-i18next";
class Filter extends DynamicEntityQueryFilter {
}
const isFilterValid = (filter) => {
    var _a;
    if (filter) {
        const isLogicGate = filter.filterType === DynamicEntityQueryFilterType.AND || filter.filterType === DynamicEntityQueryFilterType.OR;
        if (isLogicGate) {
            if (((_a = filter.operands) === null || _a === void 0 ? void 0 : _a.length) > 1) {
                for (const op of filter.operands) {
                    if (isFilterValid(op) === false) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        }
        return filter.operandID && filter.operandValue ? true : false;
    }
    return true;
};
const OperationsMapper = (filterType) => {
    switch (filterType) {
        case DynamicEntityQueryFilterType.AND:
            return "And";
        case DynamicEntityQueryFilterType.OR:
            return "Or";
        case DynamicEntityQueryFilterType.LIKE:
            return "Like";
        case DynamicEntityQueryFilterType.EQ:
            return "=";
        case DynamicEntityQueryFilterType.GT:
            return ">";
        case DynamicEntityQueryFilterType.LT:
            return "<";
        case DynamicEntityQueryFilterType.GTE:
            return ">=";
        case DynamicEntityQueryFilterType.LTE:
            return "<=";
        case DynamicEntityQueryFilterType.IN:
            return "In";
        default:
            return "";
    }
};
const QueryFilterControl = (props) => {
    const { t } = useTranslation();
    const [queryFilter, setQueryFilter] = useState(props.queryFilter ? props.queryFilter : null);
    useEffect(() => {
        props.onChange(queryFilter);
    }, [queryFilter]);
    useEffect(() => {
        if (props.queryFilter) {
            setQueryFilter(props.queryFilter);
        }
    }, [props.queryFilter]);
    const renderFilterValue = (qf) => {
        var _a, _b, _c, _d, _e;
        const customField = props.customFields.find((cf) => cf.customFieldID === qf.operandID) || new CustomFieldBase();
        const placeholder = t("GENERIC_LABEL-PLACEHOLDER_VALUE");
        return customField.fieldType === DynamicEntityFieldType.Lookup || customField.fieldType == DynamicEntityFieldType.Array ? (qf.filterType === DynamicEntityQueryFilterType.IN ? (React.createElement(Dropdown, { multiple: true, fluid: true, search: true, options: ((_b = (_a = props.lookupTablesValues) === null || _a === void 0 ? void 0 : _a.filter((l) => l.lookupTableID === customField.lookupTableID)) === null || _b === void 0 ? void 0 : _b.map((l, ind) => ({ key: ind, text: t(l.localizedKey, l.name), value: l.id }))) || [], onChange: (_, { value }) => {
                qf.operandValue = value.join(",");
                setQueryFilter(Object.assign({}, queryFilter));
            }, value: ((_c = qf.operandValue) === null || _c === void 0 ? void 0 : _c.length) > 0 ? qf.operandValue.split(",") : [], selection: true, placeholder: placeholder })) : (React.createElement(Dropdown
        // fluid
        , { 
            // fluid
            options: ((_e = (_d = props.lookupTablesValues) === null || _d === void 0 ? void 0 : _d.filter((l) => l.lookupTableID === customField.lookupTableID)) === null || _e === void 0 ? void 0 : _e.map((l, ind) => ({ key: ind, text: t(l.localizedKey, l.name), value: l.id }))) || [], onChange: (_, { value }) => {
                qf.operandValue = value;
                setQueryFilter(Object.assign({}, queryFilter));
            }, value: qf.operandValue, selection: true, placeholder: placeholder }))) : customField.fieldType === DynamicEntityFieldType.Boolean ? (React.createElement(Dropdown, { options: [
                {
                    key: true,
                    text: t("GENERIC_LABEL-YES"),
                    value: "true"
                },
                {
                    key: false,
                    text: t("GENERIC_LABEL-NO"),
                    value: "false"
                }
            ], onChange: (_, { value }) => {
                qf.operandValue = value;
                setQueryFilter(Object.assign({}, queryFilter));
            }, value: qf.operandValue, selection: true, placeholder: placeholder })) : customField.fieldType === DynamicEntityFieldType.Date ? (React.createElement(DateInput, { value: qf.operandValue, onChange: (e, data) => {
                qf.operandValue = data.value;
                setQueryFilter(Object.assign({}, queryFilter));
            }, dateFormat: "YYYY-MM-DD", placeholder: placeholder })) : (React.createElement(Input, { placeholder: placeholder, onChange: (_, { value }) => {
                qf.operandValue = value;
                setQueryFilter(Object.assign({}, queryFilter));
            }, value: qf.operandValue }));
    };
    const renderQueryFilter = (qf) => {
        var _a, _b;
        const isLogicGate = qf.filterType === DynamicEntityQueryFilterType.AND || qf.filterType === DynamicEntityQueryFilterType.OR;
        return (React.createElement(Segment, { className: "query-filter" },
            React.createElement("div", { className: "query-item" },
                React.createElement(Dropdown
                // fluid
                , { 
                    // fluid
                    placeholder: t("GENERIC_LABEL-PLACEHOLDER_FILTER_TYPE"), options: [
                        DynamicEntityQueryFilterType.AND,
                        DynamicEntityQueryFilterType.OR,
                        DynamicEntityQueryFilterType.LIKE,
                        DynamicEntityQueryFilterType.EQ,
                        DynamicEntityQueryFilterType.GT,
                        DynamicEntityQueryFilterType.GTE,
                        DynamicEntityQueryFilterType.LT,
                        DynamicEntityQueryFilterType.LTE,
                        DynamicEntityQueryFilterType.IN
                    ].map((filtertype) => {
                        const label = OperationsMapper(filtertype);
                        return {
                            key: label + filtertype,
                            text: label,
                            value: filtertype
                        };
                    }) || [], onChange: (_, { value }) => {
                        let filterType = value;
                        qf.filterType = filterType;
                        if (filterType === DynamicEntityQueryFilterType.AND || filterType === DynamicEntityQueryFilterType.OR) {
                            qf.operandID = "";
                            qf.operandValue = "";
                        }
                        setQueryFilter(Object.assign({}, queryFilter));
                    }, selection: true, value: qf.filterType })),
            !isLogicGate && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "query-item" },
                    React.createElement(Dropdown
                    // fluid
                    , { 
                        // fluid
                        options: ((_a = props.customFields) === null || _a === void 0 ? void 0 : _a.map((c, index) => ({
                            key: c.customFieldID + index,
                            text: t(c.customFieldLocalizedKey, c.customFieldName),
                            value: c.customFieldID
                        }))) || [], onChange: (_, { value }) => {
                            qf.operandID = value;
                            qf.operandValue = "";
                            setQueryFilter(Object.assign({}, queryFilter));
                        }, selection: true, placeholder: t("GENERIC_LABEL-PLACEHOLDER_CUSTOM_FIELD"), value: qf.operandID })),
                React.createElement("div", { className: "query-item" }, renderFilterValue(qf)))),
            isLogicGate && (React.createElement(React.Fragment, null, (_b = qf.operands) === null || _b === void 0 ? void 0 :
                _b.map((operand, index) => {
                    return (React.createElement(Segment, { style: { borderLeftWidth: "4px" }, key: "operand_" + index, className: "query-item flex-item" },
                        React.createElement("div", null,
                            React.createElement(Button, { style: { marginRight: "10px" }, icon: "remove", color: "red", size: "mini", onClick: () => {
                                    qf.operands = qf.operands.filter((f) => f !== operand);
                                    setQueryFilter(Object.assign({}, queryFilter));
                                } })),
                        renderQueryFilter(operand)));
                }),
                React.createElement(Button, { icon: "plus", color: "green", style: { marginTop: "10px" }, size: "mini", onClick: () => {
                        const newFilter = new Filter();
                        newFilter.key = new Date().getTime();
                        qf.operands.push(newFilter);
                        setQueryFilter(Object.assign({}, queryFilter));
                    } })))));
    };
    return (React.createElement("div", { className: "query-form" }, queryFilter ? (React.createElement(React.Fragment, null,
        props.showError && !isFilterValid(queryFilter) && (React.createElement(Label, { basic: true, color: "red", pointing: "below" }, t("GENERIC_ERROR_ALERT-FIELD_VALIDATION_QUERY_FILTER"))),
        React.createElement(Segment, { className: "flex-item" },
            React.createElement("div", null,
                React.createElement(Button, { style: { marginRight: "10px" }, icon: "remove", color: "red", size: "mini", onClick: () => setQueryFilter(null) })),
            renderQueryFilter(queryFilter)))) : (React.createElement(Button, { icon: "plus", color: "green", size: "mini", onClick: () => {
            setQueryFilter(new DynamicEntityQueryFilter());
        } }))));
};
export { QueryFilterControl, DynamicEntityQueryFilter, isFilterValid };
