import swal from "sweetalert";
class NotificationService {
    showSuccess(msg, buttonText) {
        swal({
            icon: "success",
            text: msg,
            closeOnClickOutside: false,
            buttons: {
                confirm: {
                    text: buttonText,
                    value: true,
                    visible: true,
                    className: "ui positive button",
                    closeModal: true
                }
            }
        });
    }
    showError(msg, buttonText, error) {
        if (error)
            console.log(error);
        swal({
            icon: "error",
            text: msg,
            closeOnClickOutside: false,
            buttons: {
                confirm: {
                    text: buttonText,
                    value: true,
                    visible: true,
                    className: "ui positive button",
                    closeModal: true
                }
            }
        });
    }
    showWarning(msg, btnConfirmText, btnCancelText, callbackFN) {
        swal({
            icon: "warning",
            text: msg,
            closeOnClickOutside: false,
            buttons: {
                confirm: {
                    text: btnConfirmText,
                    value: true,
                    visible: true,
                    className: "ui negative button",
                    closeModal: true
                },
                cancel: {
                    text: btnCancelText,
                    value: false,
                    visible: true,
                    className: "ui button",
                    closeModal: true
                }
            }
        }).then((value) => {
            if (value === true) {
                callbackFN();
            }
        });
    }
}
export default new NotificationService();
