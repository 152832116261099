import moment from "moment";
const formatDateToLocal = (data) => {
    var formatedDate = moment(data);
    return formatedDate.format("L");
};
const formatDate = (data, dateOptions) => {
    var formatedDate = moment(data);
    return formatedDate.format(dateOptions);
};
const formatCurrency = (data, currencyOption) => {
    return Intl.NumberFormat(undefined, { currency: currencyOption, style: 'currency' }).format(data);
};
const formatNumberToFixDecimals = (data, numberOfDecimals) => {
    return Number(data).toFixed(numberOfDecimals);
};
const showShorterText = (data) => {
    let aux = data;
    return aux.slice(0, 50) + "...";
};
export { formatDateToLocal, formatDate, formatCurrency, formatNumberToFixDecimals, showShorterText };
