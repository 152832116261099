import React, { useContext } from "react";
export const AuthContext = React.createContext({
    userProfile: {
        roles: [],
        displayName: "",
        userId: ""
    },
    areaAccesRules: {}
});
export const useAuthContext = () => useContext(AuthContext);
