import React from "react";
import { Input } from "semantic-ui-react";
const getDecimal = (value) => {
    let floatNumber = parseFloat(value);
    return Number.isNaN(floatNumber) ? 0 : floatNumber;
};
const DecimalInput = (props) => {
    const value = props.defaultValue;
    const onChange = (_, { value }) => {
        if (value.substr(value.length - 1) === '.') {
            props.onChange(value);
        }
        else {
            const newValue = getDecimal(value);
            props.onChange(newValue);
        }
    };
    const inputProps = Object.assign(Object.assign({}, props), { onChange, value, defaultValue: undefined });
    return React.createElement(Input, Object.assign({}, inputProps));
};
export { DecimalInput };
