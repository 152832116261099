function searchArray(items, searchKeyWord, displayConfig, isCaseSensitive = false) {
    let filteredItems = [];
    for (let item of items) {
        if (meetCondition(item, searchKeyWord, displayConfig, isCaseSensitive)) {
            filteredItems.push(item);
        }
    }
    return filteredItems;
}
function meetCondition(item, searchKeyWord, displayConfig, isCaseSensitive = false) {
    if (!isCaseSensitive) {
        searchKeyWord = searchKeyWord.toLocaleLowerCase();
    }
    for (let displayProperty of displayConfig) {
        let key = displayProperty[0];
        let customRender = displayProperty[1];
        let formater = displayProperty[2];
        let searchCrit = displayProperty[4];
        let customRenderUseFormatData = displayProperty[3];
        let isValid;
        let text;
        let displayValue = item[key];
        if (formater != null) {
            for (let formatMethod of formater) {
                displayValue = formatMethod(displayValue);
            }
        }
        if (customRender != null) {
            let renderVal;
            if (customRenderUseFormatData && formater != null) {
                renderVal = customRender(Object.assign(Object.assign({}, item), { [key]: displayValue }));
            }
            else {
                renderVal = customRender(item);
            }
            if ((renderVal === null || renderVal === void 0 ? void 0 : renderVal.props) != null && typeof renderVal.props.children == "string") {
                displayValue = renderVal.props.children;
            }
        }
        text = (displayValue + '');
        if (!isCaseSensitive) {
            text = text.toLocaleLowerCase();
        }
        if (typeof searchCrit == "string") {
            switch (searchCrit) {
                case "contain":
                    isValid = containSearch(text, searchKeyWord);
                    break;
                case "strict":
                    isValid = strictSearch(text, searchKeyWord);
                    break;
                case "beginWith":
                    isValid = beginWithSearch(text, searchKeyWord);
                    break;
                case "regex":
                    isValid = regexSearch(text, searchKeyWord);
                    break;
                default:
                    break;
            }
        }
        else if (typeof searchCrit == "function") {
            let customSearchCriteria = searchCrit;
            isValid = customSearchCriteria(text, searchKeyWord);
        }
        if (isValid) {
            return true;
        }
    }
    return false;
}
function strictSearch(text, searchKeyWord) {
    return text == searchKeyWord;
}
function containSearch(text, searchKeyWord) {
    return text.indexOf(searchKeyWord) > -1 ? true : false;
}
function beginWithSearch(text, searchKeyWord) {
    return text.indexOf(searchKeyWord) == 0 ? true : false;
}
function regexSearch(text, searchKeyWord) {
    let modifier = "gi";
    let searchPattern = '.*' + searchKeyWord.split('').join(".*") + '.*';
    const searchRegex = new RegExp(searchPattern, modifier);
    return searchRegex.test(text);
}
export { searchArray };
