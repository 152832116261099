var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef } from "react";
import { DynamicItemsViewer } from "../DynamicItemsViewer/DynamicItemsViewer";
import { useTranslation } from "react-i18next";
export const PhotoDetailsDisplayControl = (props) => {
    const { t, i18n } = useTranslation();
    const photoCanvas = useRef(null);
    const canvasWrapper = useRef(null);
    useEffect(() => {
        handleImageDisplay(props.selectedPhoto, null);
    }, [photoCanvas]);
    const handleImageDisplay = (photo, photoDetail) => {
        var _a;
        let canvas = photoCanvas === null || photoCanvas === void 0 ? void 0 : photoCanvas.current;
        let ctx = canvas === null || canvas === void 0 ? void 0 : canvas.getContext("2d");
        const maxW = canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.current.parentElement.clientWidth;
        const maxH = maxW;
        const image = new Image();
        image.src = ((_a = photo === null || photo === void 0 ? void 0 : photo.url) === null || _a === void 0 ? void 0 : _a.length) > 0 ? photo === null || photo === void 0 ? void 0 : photo.url : "data:image/png;base64," + photo.data;
        image.onload = () => {
            const iw = image.width;
            const ih = image.height;
            const scale = Math.min((maxW / iw), (maxH / ih));
            const iwScaled = iw * scale;
            const ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(image, 0, 0, iwScaled, ihScaled);
            if (photoDetail) {
                let left = photoDetail.left * scale;
                let top = photoDetail.top * scale;
                let width = photoDetail.width * scale;
                let height = photoDetail.height * scale;
                ctx.beginPath();
                ctx.lineWidth = 3;
                ctx.strokeStyle = "yellow";
                ctx.rect(left, top, width, height);
                ctx.stroke();
            }
        };
    };
    const handleDetailClick = (photoDetail) => __awaiter(void 0, void 0, void 0, function* () {
        handleImageDisplay(props.selectedPhoto, photoDetail);
    });
    return (React.createElement("div", { className: "photo-modal-content" },
        React.createElement("div", { ref: canvasWrapper, className: "canvas-wrapper" },
            React.createElement("canvas", { ref: photoCanvas, className: "modal-photo" })),
        React.createElement("div", { className: "modal-photo-details" },
            React.createElement(DynamicItemsViewer, { items: props.selectedPhotoDetails, pagination: false, properties: [
                    {
                        key: "tagId",
                        label: t("PAGE_PHOTO_DETAILS-TAG_ID"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    },
                    {
                        key: "tagName",
                        label: t("PAGE_PHOTO_DETAILS-TAG_NAME"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    },
                    {
                        key: "tagType",
                        label: t("PAGE_PHOTO_DETAILS-TAG_TYPE"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    },
                    {
                        key: "description",
                        label: t("PAGE_PHOTO_DETAILS-DESCRIPTION"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    },
                    {
                        key: "probability",
                        label: t("PAGE_PHOTO_DETAILS-PROBABILITY"),
                        searchable: true,
                        displayPlaces: ["cardHeader", "table"]
                    }
                ], onItemsClick: handleDetailClick, cutoffScreenSize: 0 }))));
};
