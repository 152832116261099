import React from "react";
export const isAllowed = (roles, accessRules, area) => {
    try {
        let cRoles = roles ? roles : [];
        for (let cRole of cRoles) {
            const permissions = accessRules[cRole];
            if (!permissions) {
                continue;
            }
            if (permissions && permissions.includes(area)) {
                // static rule not provided for action
                return true;
            }
        }
        return false;
    }
    catch (_a) {
        console.log("AuthSwitchFailed");
        return false;
    }
};
export const AuthSwitch = (props) => {
    return (React.createElement(React.Fragment, null, isAllowed(props.roles, props.areaAccessRules, props.area) ? props === null || props === void 0 ? void 0 : props.renderAllowed() : props === null || props === void 0 ? void 0 : props.renderDenied()));
};
AuthSwitch.defaultProps = {
    renderAllowed: () => null,
    renderDenied: () => null
};
