function sortArray(items, key, customRender, formaters, customRenderUseFormatData, isAscending) {
    let sortedItems = [];
    if (isAscending) {
        sortedItems = [...items].sort((a, b) => {
            let valueA = getSortValue(a, key, customRender, formaters, customRenderUseFormatData);
            let valueB = getSortValue(b, key, customRender, formaters, customRenderUseFormatData);
            if (typeof valueA == "number") {
                return valueA - valueB;
            }
            else if (typeof valueA == "string") {
                return valueA.localeCompare(valueB);
            }
            else {
                return (valueA - valueB);
            }
        });
    }
    else {
        sortedItems = [...items].sort((a, b) => {
            let valueA = getSortValue(a, key, customRender, formaters, customRenderUseFormatData);
            let valueB = getSortValue(b, key, customRender, formaters, customRenderUseFormatData);
            if (typeof valueA == "number") {
                return valueB - valueA;
            }
            else if (typeof valueB == "string") {
                return valueB.localeCompare(valueA);
            }
            else {
                return valueB - valueA;
            }
        });
    }
    return sortedItems;
}
function getSortValue(item, key, customRender, formaters, customRenderUseFormatData) {
    let displayValue = item[key];
    if (formaters != null) {
        for (let formatMethod of formaters) {
            displayValue = formatMethod(displayValue);
        }
    }
    if (customRender != null) {
        let renderVal;
        if (customRenderUseFormatData && formaters != null) {
            renderVal = customRender(Object.assign(Object.assign({}, item), { [key]: displayValue }));
        }
        else {
            renderVal = customRender(item);
        }
        if ((renderVal === null || renderVal === void 0 ? void 0 : renderVal.props) != null &&
            (typeof renderVal.props.children == "string" || typeof renderVal.props.children == "number")) {
            displayValue = renderVal.props.children;
        }
    }
    return displayValue;
}
export { sortArray };
