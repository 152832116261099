import React from "react";
import { useState } from "react";
import { Icon, Input, Segment } from "semantic-ui-react";
import { debounce } from "../../../Utility/utility";
import { ItemCard } from "../ItemCard";
import { ItemsPerPageDropdown } from "../ItemsPerPageDropdown";
import { SortingPropertiesDropdown } from "../SortingPropertiesDropdown";
export const ControlledItemsCardView = (props) => {
    const { properties, items } = props;
    const [arrowDirection, setArrowDirection] = useState("arrow down");
    const getNumberOfPages = () => {
        return props.pagination ? Math.ceil(props.numberOfItems / props.numberOfItemsShown) : 1;
    };
    return (React.createElement("div", null,
        React.createElement(Segment, { attached: props.attached },
            React.createElement("div", { className: "items-view-row items-view-flex-row" },
                React.createElement("div", { className: "items-view-flex-item" },
                    React.createElement(SortingPropertiesDropdown, { setSortingOrder: (newState) => setArrowDirection(newState), setSortingProperty: (newState) => props.onSortingPropertyChanged(newState), properties: properties })),
                React.createElement("div", { className: "items-view-flex-item" },
                    React.createElement(Icon, { name: arrowDirection, size: "big", onClick: () => {
                            if (arrowDirection === "arrow down") {
                                setArrowDirection("arrow up");
                                props.onSortingDirectionChanged("ascending");
                            }
                            else {
                                setArrowDirection("arrow down");
                                props.onSortingDirectionChanged("descending");
                            }
                        }, onMouseOver: (e) => {
                            e.target.style.cursor = "pointer";
                        } }))),
            (properties.some((pr) => pr.searchable) || props.pagination || props.customTools) && (React.createElement("div", { className: "items-view-row items-view-flex-row" },
                React.createElement("div", { className: "items-view-flex-item" },
                    React.createElement(ItemsPerPageDropdown, { noOfItemsToShowArray: props.noOfItemsToShowArray, setItemsPerPage: (newState) => {
                            props.onNumberOfItemsShownChanged(newState);
                            if (props.onActivePageChanged) {
                                props.onActivePageChanged(1);
                            }
                        }, selectedValue: props.numberOfItemsShown })),
                props.pagination && getNumberOfPages() >= 2 && (React.createElement("div", { className: "items-view-flex-item" },
                    React.createElement(Icon, { size: "large", name: "angle left", onClick: () => {
                            if (props.activePage > 1) {
                                props.onActivePageChanged(props.activePage - 1);
                            }
                        }, onMouseOver: (e) => {
                            e.target.style.cursor = "pointer";
                        } }),
                    props.activePage,
                    " / ",
                    props.pagination ? Math.ceil(items.length / props.numberOfItemsShown) : 1,
                    React.createElement(Icon, { size: "large", name: "angle right", onClick: () => {
                            if (props.activePage < getNumberOfPages()) {
                                props.onActivePageChanged(props.activePage + 1);
                            }
                        }, onMouseOver: (e) => {
                            e.target.style.cursor = "pointer";
                        } }))))),
            React.createElement("div", { className: "items-view-row items-view-flex-item" },
                React.createElement(Input, { fluid: true, icon: "search", placeholder: "Search...", onChange: debounce((event, data) => {
                        props.onSearchKeyWordChanged(data.value);
                        if (props.onActivePageChanged) {
                            props.onActivePageChanged(1);
                        }
                    }) })),
            React.createElement("div", { className: "items-view-row items-view-flex-item" }, props.customTools &&
                props.customTools.map((el) => {
                    return React.createElement("div", { className: "items-view-flex-item" }, el);
                }))),
        items.map((item, index) => {
            return React.createElement(ItemCard, { item: item, key: index, properties: properties, itemActions: props.itemActions });
        })));
};
